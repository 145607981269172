<template>
<div class="scroll-y-con">

  <div class="top-title">
    <div>{{`子订单ID: ${orderNo} (${getOrderStatus(data.order_status)})`}}
      <!-- <template v-if="data.order_status == 1 && data.order_source == 10">
        <a-button type="link" @click="showPay=true">支付</a-button>
        <pay-tip />
      </template> -->
    </div>
    <div>
      <a-button
        v-if="[1,2,3].includes(data.order_status)"
        type="link" class="p-0 mr-4"
        @click="handlerCancel">取消订单</a-button>
      <a-button 
        v-if="[1,2,3,4].includes(data.order_status)"
        type="link" class="p-0 mr-4"
        @click="()=>{this.isShowPrint = true}"
      >打印</a-button>
      <a-tag v-if="data.status == -1" class="mr-0" color="red">订单已删除</a-tag>
    </div>
  </div>

  <div class="container page-content">
    <a-descriptions bordered title size="middle" class="descri-class" :column="24">
      <!-- 订单信息 -->
      <a-descriptions-item :span="10" label="订单ID">{{ data.order_no }}</a-descriptions-item>
      <a-descriptions-item :span="7" label="订单来源">{{ formatOrderSource(data.order_source) }}</a-descriptions-item>
      <a-descriptions-item :span="7" label="支付方式">{{ getPayTypeShowName(data) }}</a-descriptions-item>

      <!-- 订单金额 -->
      <template>
        <a-descriptions-item v-if="data.is_cash_delivery==2" 
            :span="10" label="总订单金额">￥{{data.order_amount}}</a-descriptions-item>
        <a-descriptions-item v-else 
            :span="10" label="总订单金额">￥{{data.order_amount}}，{{data.order_financial_account_name}}</a-descriptions-item>
      </template>
      <!-- <a-descriptions-item :span="7" label="总订单金额">￥{{data.order_amount}}</a-descriptions-item> -->
      <template>
        <a-descriptions-item :span="14" label="子订单金额">
          ￥{{data.suborderTotalAmount}}
          <template v-if="data.freight_amount>0">（含运费￥{{data.freight_amount}}）</template>
          <template v-if="data.pack_amount>0">（含保温袋￥{{data.pack_amount}}）</template>
        </a-descriptions-item>
      </template>

      <!-- 下单信息 -->
      <a-descriptions-item :span="10" label="下单时间">{{ data.add_time }}</a-descriptions-item>
      <template v-if="data.order_source ===10 || data.order_source ===15">
        <a-descriptions-item :span="7" label="下单人">{{ data.place_order_name }}</a-descriptions-item>
        <a-descriptions-item :span="7" label="下单人电话">{{ data.place_order_phone }}</a-descriptions-item>
      </template>
      <template v-else>
        <template v-if="!isDrink">
          <a-descriptions-item :span="7" label="联系人">{{ data.delivery_info && data.delivery_info.contacts_name }}</a-descriptions-item>
          <a-descriptions-item :span="7" label="联系人电话">{{ data.delivery_info && data.delivery_info.contacts_phone }}</a-descriptions-item>
        </template>
      </template>

      <!-- 自提信息 -->
      <template v-if="data.order_type == 1">
        <!-- 自提人 信息 -->
        <a-descriptions-item :span="10" label="自提时间"
          v-if="!isDrink">{{ getGoodsTime(data) }}</a-descriptions-item>
        <a-descriptions-item :span="7" label="取货人">{{ data.pick_up_info.recipient_name }}</a-descriptions-item>
        <a-descriptions-item :span="7" label="取货人电话">{{ data.pick_up_info.recipient_phone }}</a-descriptions-item>
        <!-- 自提 进展 -->
        <a-descriptions-item :span="10" label="自提进展">
          <template>{{getOrderStatus(data.order_status)}}</template>
          <a v-if="data.order_status==3" type="link" class="ml-6" @click="handlerCompletePick">点击完成自提</a>
        </a-descriptions-item>
        <a-descriptions-item :span="14" label="取货码">{{data.pick_up_info.pick_up_code}}</a-descriptions-item>
        <!-- <a-descriptions-item :span="3" label="收款主体">{{data.order_financial_account_name}}</a-descriptions-item> -->
      </template>

      <!-- 配送信息 -->
      <template v-if="data.order_type == 2">
        <!-- 配送进展 信息 -->
        <a-descriptions-item :span="10" label="配送进展">
          <template>{{getOrderStatus(data.order_status)}}</template>
          <a v-if="data.order_status==2 && data.courier_info && data.courier_info.courier_phone" 
              type="link" class="ml-6" @click="handlerQuery(2)">查看地图</a>
        </a-descriptions-item>
        <a-descriptions-item :span="7" label="配送员">{{ data.courier_info && data.courier_info.courier_name }}</a-descriptions-item>
        <a-descriptions-item :span="7" label="配送员电话">{{ data.courier_info && data.courier_info.courier_phone }}</a-descriptions-item>
        <!-- 收货人 信息 -->
        <a-descriptions-item :span="10" label="收货时间">{{ getGoodsTime(data) }}</a-descriptions-item>
        <a-descriptions-item :span="7" label="收货人">{{ data.delivery_info && data.delivery_info.consignee_name }}</a-descriptions-item>
        <a-descriptions-item :span="7" label="收货人电话">{{ data.delivery_info ? data.delivery_info.consignee_phone:'' }}</a-descriptions-item>
          
        <a-descriptions-item :span="24" label="收件地址">{{data.delivery_info.consignee_address}}</a-descriptions-item>
        <!-- <a-descriptions-item :span="3" label="收款主体">{{data.is_cash_delivery == 2 ? '' :data.order_financial_account_name}}</a-descriptions-item> -->
      </template>

      <!-- 备注 -->
      <a-descriptions-item :span="24" label="下单备注">{{ data.order_remarks }}</a-descriptions-item>
      <a-descriptions-item :span="24" label="客服备注">{{ data.customer_service_remarks }}</a-descriptions-item>
      <a-descriptions-item :span="24" label="内部备注">
        <div style="white-space: pre-wrap;">{{ data.private_remarks }}</div>
      </a-descriptions-item>
    </a-descriptions>

    <a-divider />
    <div>
      <div class="antd-pro-pages-profile-basic-style-title">订单商品</div>
      <base-table v-if="isDrink"
        ref="orderDetilTableRef"
        id="orderDetilTableID"
        :columnsData="detail_columns_drink"
        :rowKey="detail_rowKey"
        :tableData="data.merge_goods_list">
        <template #status="{ text }">
          <a-tag v-if="text == 2" class="mr-0" color="gray">已拆单顺丰</a-tag>
          <a-tag v-if="text == 3" class="mr-0" color="red">已申请退款</a-tag>
          <a-tag v-if="text == 4" class="mr-0" color="red">已退款</a-tag>
        </template>
      </base-table>
      <base-table v-else
        ref="orderDetilTableRef"
        id="orderDetilTableID"
        :columnsData="detail_columns"
        :rowKey="detail_rowKey"
        :tableData="tableData">
        <template #status="{ text }">
          <a-tag v-if="text == 2" class="mr-0" color="gray">已拆单顺丰</a-tag>
          <a-tag v-if="text == 3" class="mr-0" color="red">已申请退款</a-tag>
          <a-tag v-if="text == 4" class="mr-0" color="red">已退款</a-tag>
        </template>
      </base-table>

      <div
        class="mt-2 justify-between"
        v-if="data.cake_accessory_list && data.cake_accessory_list.length > 0"
      > 
        <div>
          <span class="text-sm font-bold text-black">蛋糕配件</span>
        </div>
        <div v-for="(item,i) in data.cake_accessory_list" :key="i">
          <div class="flex mt-2">
            <div>蛋糕名称：{{ item.goods_name }}</div>
            <div class="ml-10">蜡烛：{{ formatBirthdayCandle(item.birthday_candle) }}</div>
            <div class="ml-10">餐具：{{ item.diners_number }}</div>
            <div class="ml-10">生日牌：{{ item.birthday_card }}</div>
          </div>
        </div>
      </div>
    </div> 
    <div class="mt-2">
      <a-button class="bottom-btn" type="link" @click="isShowFlow = true">订单流水</a-button>
      <a-button class="bottom-btn" type="link" @click="isShowOptLog = true">操作记录</a-button>
    </div>

    <!-- 物流 -->
    <QueryWindow v-if="isShowQuery" :show.sync="isShowQuery" 
        :requestID="data.order_no" 
        :hasDelivery="query_delivery" 
        :hasExpress="query_express" />
    <!-- 弹窗：流水、操作记录 -->
    <flow-modal v-if="isShowFlow" :show.sync="isShowFlow" :orderId="data.order_no" />
    <opt-modal v-if="isShowOptLog" :show.sync="isShowOptLog" :orderId="data.order_no" />

    <!-- 打印弹窗 -->
    <print-modal v-if="isShowPrint"
                 :show.sync="isShowPrint"
                 :orderNo="data.order_no"
                 :subOrderNo="data.suborder_no" />
    <!-- 取消弹窗（包含其他支付） -->
    <cancel-modal v-if="isShowCancel"
         :show.sync="isShowCancel"
         :payInfo="data"
         @ok="handlerCancelSure" />

  </div>

</div>
</template>

<script>
import { 
  getShopOrderDesc,
  updateOrderStatus,
  cancelOrder
} from "@/api/shop.js"
import pageData from "./columns"
import {
  formatBirthdayCandle,
  formatOrderSource,
  payTypeList,
  subCODPayTypeList,
  // subOnAccountPayTypeList
} from "@/utils/type"
import QueryWindow from "@/components/order/queryWindow"
import FlowModal from "@/components/order/flow-modal.vue"
import OptModal from "@/components/order/opt-modal.vue"
import printModal from "./components/print-modal.vue"
import CancelModal from '@/components/order/cancel-modal.vue'

export default {
  components: {
    QueryWindow,
    FlowModal, 
    OptModal,
    printModal,
    CancelModal
  },
  props: {
    orderNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ...pageData,
      requestID: "",
      tableData: [],
      data: {
        pick_up_info: {
          pick_up_code: "",
        },
      },

      isDrink: false,

      isOtherPlatOrder: false,
      isCashierOrder: false,
      payedAmount: 0,

      isShowFlow: false,
      isShowOptLog: false,
      isShowQuery: false,
      query_delivery: false,
      query_express: false,
      isShowPrint: false,
      isShowCancel: false,
    }
  },
  created() {
    if(this.orderNo){ // 如果是抽屉页
      // this.isInDrawer = true
    }else if(this.$route.query.order_no){ // 如果是新开页
      this.orderNo = this.$route.query.order_no
    }

    this.initData()
  },
  methods: {
    formatBirthdayCandle,
    formatOrderSource,

    async initData() {
      const { data, code } = await getShopOrderDesc({
        suborder_no: this.orderNo,
      })
      if (code === 0) {
        data.freight_amount  = Number(data.freight_amount)
        data.suborder_amount = Number(data.suborder_amount)
        data.suborderTotalAmount = Math.round((Number(data.suborder_amount)+Number(data.freight_amount)+Number(data.pack_amount||0))*100)/100

        this.isDrink = data.goods_list.some(gd=>gd.goods_type==4)
        // 好茶：商品属性、合并
        if(this.isDrink){
          const merge_goods_list = []
          data.goods_list.map(sku=>{
            const merge_sku = merge_goods_list.find(el=>{
              return el.sku_id == sku.sku_id
                    && el.drink_temperature_name == sku.drink_temperature_name
                    && el.drink_sugar_name == sku.drink_sugar_name
                    && el.drink_straw_name == sku.drink_straw_name
            })
            if(merge_sku){
              sku.show_desc = merge_sku.show_desc
              merge_sku.number = merge_sku.number + sku.number
              merge_sku.goods_price = Math.round((Number(merge_sku.goods_price) + Number(sku.goods_price))*100)/100
              merge_sku.order_price = Math.round((Number(merge_sku.order_price) + Number(sku.order_price))*100)/100
            }else{
              const tmp = []
              // if(sku.flavor_name)            tmp.push(sku.flavor_name)
              if(sku.specification_name)     tmp.push(sku.specification_name)
              if(sku.drink_temperature_name) tmp.push(sku.drink_temperature_name)
              if(sku.drink_sugar_name)       tmp.push(sku.drink_sugar_name)
              if(sku.drink_straw_name)       tmp.push(sku.drink_straw_name)
              sku.show_desc = tmp.join("；")
              merge_goods_list.push({ ...sku })
            }
          })
          data.merge_goods_list = merge_goods_list
        }

        this.data = data
        this.tableData = data.goods_list.filter(el=>el.status !=2)
        this.isOtherPlatOrder = data.order_source >= 20
        this.isCashierOrder = data.order_source == 16

        // vip_card_pay_amount float 钱包支付金额
        // stored_value_card_pay_amount  float 实体卡支付金额
        // wx_pay_amount float 微信支付金额
        // alipay_amount float 支付宝支付金额
        // cash_delivery_amount  float 货到付款金额
        // other_pay_amount  float 其它支付金额
        // on_account_amount float 挂账金额
        this.payedAmount = Math.round(( Number(data.vip_card_pay_amount) + 
                            Number(data.stored_value_card_pay_amount) + 
                            Number(data.wx_pay_amount) + 
                            Number(data.alipay_amount) + 
                            Number(data.cash_delivery_amount) + 
                            Number(data.other_pay_amount) + 
                            Number(data.on_account_amount) )*100)/100
      }
    },
    // 完成自提
    async handlerCompletePick() {
      const { code } = await updateOrderStatus({
        suborder_no: this.orderNo,
        status: 30,
      })
      if (code === 0) {
        this.$message.success("已完成")
        this.initData()
      }
    },

    handlerCancel(){
      if(this.checkNotOtherPlatOrder()){
        // 货到付款 & 已开始支付：不能取消
        if(this.data.is_cash_delivery == 2 && this.payedAmount > 0){
          this.$message.info("货到付款订单，已开始支付、不能取消订单")
        }else{
          this.isShowCancel = true
        }
      }
    },
    // 取消订单
    handlerCancelSure(refundData){
      let params = {
        ...refundData,
        suborder_no: this.data.suborder_no
      }
      const _this = this
      cancelOrder(params).then(res => {
        if(res.code == 0){
          _this.isShowCancel = false
          _this.$message.success("已取消")
          _this.initData()
        } 
      })
    },

    checkNotOtherPlatOrder(){
      if(this.isOtherPlatOrder){
        this.$message.info("第三方订单，请联系客服在母订单操作")
        return false
      }
      if(this.isCashierOrder){
        this.$message.info("需要在收银软件操作")
        return false
      }
      return true
    },

    getGoodsTime(data) {
      if (data.order_type === 2) {
        return (
          data.delivery_info.delivery_time_start.replace(/:00$/, "") +
          "-" +
          data.delivery_info.delivery_time_end.split(" ")[1].replace(/:00$/, "")
        )
      } else {
        if (data.pick_up_info && data.pick_up_info.pick_up_goods_time_start)
          return (
            data.pick_up_info.pick_up_goods_time_start.replace(/:00$/, "") +
            "-" +
            data.pick_up_info.pick_up_goods_time_end.split(" ")[1].replace(/:00$/, "")
          )
      }
    },
    getOrderStatus(id) {
      // 1-待配送，2-配送中，3-自提未完成，4-已完成，5-已取消
      const statusOBj = {
        1: "待配送",
        2: "配送中",
        3: "待自提",
        4: "已完成",
        5: "已取消",
      }
      return statusOBj[id]
    },
    // 支付方式（给网点展示的略有隐藏）
    getPayTypeShowName(data){
      // 货到付款
      if(data.pay_type == 10){ // 货到付款
        if(data.pay_subtype > 0){
          return "货到付款-" + this.getSubPayTypeName(data.pay_subtype)
        }
        return "货到付款"
      }else if(data.is_cash_delivery == 2){ // 货到付款 转在线支付 但没支付（这种不存在了）
        return "货到付款"
      }

      // 小程序、官网 下单
      if(data.order_source < 10){
        return this.getPayTypeName(data.pay_type)
      }else{
        // 客服下单、城市客服下单
        if(data.order_source == 10){
          return "挂账(" + data.order_financial_account_name + ")"
        }else if(data.order_source == 15){
        // 网点自建单
          return "挂账(门店)"
        }else if(data.order_source == 16){
        // 收银下单
          return "挂账(收银)"
        }else if(data.order_source >= 20){
        // 第三方订单
          return "挂账(" + data.order_financial_account_name + ")"
        }
      }

      return "未知"
    },
    // 支付方式
    getPayTypeName(id) {
      let item = payTypeList.find(el=>{
        if(id == el.id) return el
      })
      return item && item.name || ""
    },
    getSubPayTypeName(id) {
      let item = subCODPayTypeList.find(el=>{
        if(id == el.id) return el
      })
      // let item = subCODPayTypeList.concat(subOnAccountPayTypeList).find(el=>{
      //   if(id == el.id) return el
      // })
      return item && item.name || ""
    },

    // 物流
    handlerQuery(subOrderType) {
      this.query_delivery = subOrderType==2
      this.query_express  = subOrderType==3
      this.isShowQuery = true
    },
  },
}
</script>

<style lang="less">

.descri-class.ant-descriptions-bordered.ant-descriptions-middle
  .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 10px;
}

.bottom-btn{
  padding: 0;
  margin-right: 20px;
}
.pre{
  white-space: pre;
  display: inline-block;
  vertical-align: top;
}
</style>
