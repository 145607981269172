<template>
  <a-modal
    :visible.sync="show"
    title="配送站订单打印"
    destroyOnClose
    width="740px"
    @cancel="handlerCancel"
    :footer="null"
  >
    <div class="justify-center print-con">
      <iframe v-if="printSrc" :src="printSrc" id="print-page"></iframe>
    </div>
    
    <div v-if="printSrc" class="mt-2 mb-2 flex justify-center">
      <a type="link" @click="handlerPrint">打印</a>
    </div>
  </a-modal>
</template>

<script>

export default {
  components: { 

  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderNo: {
      type: String,
      default: '',
    },
    subOrderNo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      printSrc: "",
    }
  },
  created(){
    const newPage = this.$router.resolve({
      path: "/shop-order-print",
      query: { 
        order_no: this.orderNo,
        suborder_no: this.subOrderNo
      },
    });

    this.printSrc = newPage.href
  },
  mounted() {

  },
  methods: {
    handlerPrint(){
      let page = document.getElementById("print-page")
      if(page){
        page.focus();
        page.contentWindow.print();
      }
    },
    handlerCancel(){
      this.$emit('update:show', false)
    }
  }
}
</script>

<style>
.print-con{
  width: 100%;
  height: 450px;
}
.print-con iframe{
  width: 100%;
  height: 100%;
  border: none;
}

</style>